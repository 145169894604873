import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import '@reach/slider/styles.css';

import {
  SliderInput,
  SliderTrack,
  SliderTrackHighlight,
  SliderHandle,
  SliderMarker,
} from '@reach/slider';
import colors from '../../../../../styles/colors';

const StyledSliderInput = styled(SliderInput)`
  && {
    &[data-orientation='horizontal'] {
      width: auto;

      margin: ${rem(16)} 0;
    }
  }
`;

const StyledSliderTrack = styled(SliderTrack)`
  && {
    background-color: #343434;
    border-radius: ${21 / 2}em;

    &[data-orientation='horizontal'] {
      height: 21px;
    }
  }
`;

const StyledSliderTrackHighlight = styled(SliderTrackHighlight)`
  && {
    background-color: ${colors.azure};
  }
`;

const StyledSliderHandle = styled(SliderHandle)`
  && {
    background-color: ${colors.azure};

    &[aria-orientation='horizontal'] {
      width: 33px;
      height: 33px;

      border: 6px solid ${colors.mineShaft};
      border-radius: 16.5px;
      transform: translate(-50%, -50%);
    }
  }
`;

const StyledSliderMarker = styled(SliderMarker)`
  && {
    top: -4px;

    width: 4px;
    height: 8px;

    color: ${colors.azure};

    background-color: #343434;
    border: 0;
    border-radius: 0;

    > span {
      position: absolute;
      bottom: 0;

      display: block;

      margin-bottom: 0.25em;

      word-break: initial;

      pointer-events: none;
    }

    &[data-value='0'],
    &[data-value='10'] {
      width: 0;
      height: 0;
    }

    &[data-value='5'] {
      > span {
        transform: translateX(-50%);
      }
    }

    &[data-value='10'] {
      > span {
        transform: translateX(-100%);
      }
    }
  }
`;

const Slider = (props) => (
  <StyledSliderInput {...props}>
    {({ value }) => (
      <StyledSliderTrack>
        <StyledSliderTrackHighlight />
        <StyledSliderHandle style={{ left: `${value * 10}%` }} />
        {new Array(11).fill().map((_, index) => (
          <StyledSliderMarker value={index * 1}>
            {index === 0 && <span>Aucunement</span>}
            {index === 5 && <span>Neutre</span>}
            {index === 10 && <span>Énormément</span>}
          </StyledSliderMarker>
        ))}
      </StyledSliderTrack>
    )}
  </StyledSliderInput>
);

export default Slider;

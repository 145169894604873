// Vendors
import React from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { rem } from 'polished';

import Slider from './Slider';

import theme from '../../../../styles/theme';
import { greaterThan } from '../../../../utils/mediaQuery';
import randomString from '../../../../utils/randomString';

import TextInputField from '../../../../components/TextInputField';
import Checkbox from '../../../../components/Checkbox';
import FormHelperText from '../../../../components/FormHelperText';

import {
  Stack,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../../../../components/Form/Form.styles';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';

export const title = 'Allergies et Habitudes de vie';

export const validationSchema = Yup.object().shape({
  hasFoodAllergies: Yup.string().required('Sélectionnez une réponse'),
  foodAllergies: Yup.string().when('hasFoodAllergies', {
    is: (val) => val === 'true',
    then: Yup.string().required('Inscrivez votre réponse'),
  }),
  smoking: Yup.string().required('Sélectionnez une réponse'),
  smokingHabits: Yup.string().when('smoking', {
    is: (val) => val === 'true',
    then: Yup.string().required('Inscrivez votre réponse'),
  }),
  alcohol: Yup.string().required('Sélectionnez une réponse'),
  alcoholHabits: Yup.string().when('alcohol', {
    is: (val) => val === 'true',
    then: Yup.string().required('Inscrivez votre réponse'),
  }),
  drugs: Yup.string().required('Sélectionnez une réponse'),
  drugsHabits: Yup.string().when('drugs', {
    is: (val) => val === 'true',
    then: Yup.string().required('Inscrivez votre réponse'),
  }),
  active: Yup.string().required('Sélectionnez une réponse'),
  nervousAgainstDentalTreatments: Yup.number()
    .required('Sélectionnez une réponse')
    .min(0, 'Sélectionnez une réponse')
    .max(10, 'Sélectionnez une réponse'),
  specificAllergies: Yup.array()
    .required('Sélectionnez une réponse')
    .min(1, 'Sélectionnez une réponse'),
});

export const initialValues = {
  hasFoodAllergies: '',
  foodAllergies: '',
  specificAllergies: [],
  otherAllergies: '',
  smoking: '',
  smokingHabits: '',
  alcohol: '',
  alcoholHabits: '',
  drugs: '',
  drugsHabits: '',
  active: '',
  nervousAgainstDentalTreatments: -1,
};

const specificAllergies = [
  'Latex',
  'Codéïne',
  'Aspirine',
  'Pénicilline',
  'Iode',
  'Anesthésie locale',
  'Aucune',
];

const AllergiesLifeHabitsStep = () => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();

  const handleCheckboxChange = (e) => {
    const {
      target: { name, value },
    } = e;

    let newValues = [...values[name]];

    const index = newValues.indexOf(value);

    if (index > -1) {
      newValues = [...newValues.filter((el) => el !== value)];
    } else {
      newValues.push(value);
    }

    setFieldValue(name, newValues);
  };

  const nervousAgainstDentalTreatmentsHelperTextId = randomString();

  return (
    <Stack>
      <Fieldset>
        <Legend>Allergies</Legend>

        <FieldsetContent>
          <div
            css={`
              display: grid;
              grid-template-columns: 1fr;
              /* grid-column-gap: ${rem(96)}; */
              grid-column-gap: ${rem(54)};
              grid-row-gap: ${rem(32)};
              align-items: start;

              ${greaterThan(650)} {
                grid-template-columns: repeat(
                  auto-fill,
                  minmax(220px, auto) minmax(220px, auto)
                );
              }
              > :not(:last-child) {
                margin-bottom: ${rem(30)};
              }
            `}
          >
            <Fieldset
              css={`
                ${greaterThan(650)} {
                  grid-row: span 2;
                  grid-column: span 2;
                }
              `}
            >
              <legend
                css={`
                  color: ${theme.colors.primary};
                  font-weight: ${theme.fontWeights.medium};
                `}
              >
                Avez-vous les allergies suivantes?*
              </legend>

              <div
                css={`
                    ${theme.layouts.grid('250px', rem(32), 'auto-fill')}

                    margin-top: ${rem(32)};
                  `}
              >
                {specificAllergies.map((specificAllergy) => (
                  <Checkbox
                    key={specificAllergy}
                    name='specificAllergies'
                    value={specificAllergy}
                    onChange={handleCheckboxChange}
                    onBlur={handleBlur}
                    checked={values.specificAllergies.includes(specificAllergy)}
                    css={`
                      font-size: ${rem(24)};
                    `}
                  >
                    {specificAllergy}
                  </Checkbox>
                ))}
              </div>

              {errors.specificAllergies && (
                <FormHelperText error>
                  {errors.specificAllergies}
                </FormHelperText>
              )}
            </Fieldset>

            <RadioButtonGroup
              legend='Avez-vous des allergies alimentaires?'
              name='hasFoodAllergies'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.hasFoodAllergies}
              error={
                errors.hasFoodAllergies && touched.hasFoodAllergies
                  ? errors.hasFoodAllergies
                  : undefined
              }
              options={[
                { label: 'Oui', value: 'true' },
                { label: 'Non', value: 'false' },
              ]}
              required
              inline
              css={`
                grid-column: span 1;
                ${greaterThan(650)} {
                  grid-column: span 2;
                }
              `}
            >
              {values.hasFoodAllergies === 'true' && (
                <TextInputField
                  label='Si oui, lesquels?'
                  name='foodAllergies'
                  value={values.foodAllergies}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.foodAllergies && touched.foodAllergies}
                  helperText={touched.foodAllergies ? errors.foodAllergies : ''}
                />
              )}
            </RadioButtonGroup>

            <TextInputField
              label='Autres allergies? (spécifiez)'
              name='otherAllergies'
              value={values.otherAllergies}
              onChange={handleChange}
              onBlur={handleBlur}
              css={`
                font-size: ${rem(19)};
                font-weight: ${theme.fontWeights.medium};

                ${greaterThan(650)} {
                  grid-column: span 2;
                }
              `}
            />
          </div>
        </FieldsetContent>
      </Fieldset>

      <Fieldset>
        <Legend>Habitudes de vie</Legend>

        <div
          css={`
            margin-top: ${rem(30)};
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: ${rem(54)};
            grid-row-gap: ${rem(32)};
            align-items: start;

            ${greaterThan(650)} {
              grid-template-columns: repeat(
                auto-fill,
                minmax(220px, auto) minmax(220px, auto)
              );
            }
          `}
        >
          <RadioButtonGroup
            legend='Consommez-vous du tabac/vapoteuse?'
            name='smoking'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.smoking}
            error={
              errors.smoking && touched.smoking ? errors.smoking : undefined
            }
            options={[
              { label: 'Oui', value: 'true' },
              { label: 'Non', value: 'false' },
            ]}
            required
            inline
            css={`
              grid-column: span 1;
              ${greaterThan(650)} {
                grid-column: span 2;
              }
            `}
          >
            {values.smoking === 'true' && (
              <TextInputField
                label='Si oui, à quelle fréquence?'
                name='smokingHabits'
                value={values.smokingHabits}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.smokingHabits && touched.smokingHabits}
                helperText={touched.smokingHabits ? errors.smokingHabits : ''}
              />
            )}
          </RadioButtonGroup>

          <RadioButtonGroup
            legend="Consommez-vous de l'alcool?"
            name='alcohol'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.alcohol}
            error={
              errors.alcohol && touched.alcohol ? errors.alcohol : undefined
            }
            options={[
              { label: 'Oui', value: 'true' },
              { label: 'Non', value: 'false' },
            ]}
            required
            inline
            css={`
              grid-column: span 1;
              ${greaterThan(650)} {
                grid-column: span 2;
              }
            `}
          >
            {values.alcohol === 'true' && (
              <TextInputField
                label='Si oui, à quelle fréquence?'
                name='alcoholHabits'
                value={values.alcoholHabits}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.alcoholHabits && touched.alcoholHabits}
                helperText={touched.alcoholHabits ? errors.alcoholHabits : ''}
              />
            )}
          </RadioButtonGroup>

          <RadioButtonGroup
            legend='Consommez-vous des drogues?'
            name='drugs'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.drugs}
            error={errors.drugs && touched.drugs ? errors.drugs : undefined}
            options={[
              { label: 'Oui', value: 'true' },
              { label: 'Non', value: 'false' },
            ]}
            required
            inline
            css={`
              grid-column: span 1;
              ${greaterThan(650)} {
                grid-column: span 2;
              }
            `}
          >
            {values.drugs === 'true' && (
              <TextInputField
                label='Si oui lesquelles et à quelle fréquence?'
                name='drugsHabits'
                value={values.drugsHabits}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.drugsHabits && touched.drugsHabits}
                helperText={touched.drugsHabits ? errors.drugsHabits : ''}
              />
            )}
          </RadioButtonGroup>

          <RadioButtonGroup
            legend='Êtes-vous actif dans la vie?'
            name='active'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.active}
            error={errors.active && touched.active ? errors.active : undefined}
            options={[
              { label: 'Oui', value: 'true' },
              { label: 'Non', value: 'false' },
            ]}
            required
            inline
            css={`
              grid-column: span 1;
              ${greaterThan(650)} {
                grid-column: span 2;
              }
            `}
          />
        </div>
        <div
          css={`
            margin-top: ${rem(60)};
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: ${rem(96)};
            grid-row-gap: ${rem(32)};
            align-items: start;

            ${greaterThan(650)} {
              grid-template-columns: minmax(220px, 450px) 1fr;
            }
          `}
        >
          <div
            css={`
              display: grid;
              grid-row-gap: inherit;
              grid-column-gap: 2rem;
              align-items: start;

              @supports not (grid-template-columns: subgrid) {
                ${greaterThan(1024)} {
                  grid-template-columns: minmax(220px, 360px) minmax(264px, 1fr);
                }
              }

              grid-template-columns: subgrid;

              ${greaterThan(650)} {
                grid-column: span 2;
              }

              ${greaterThan(768)} {
                grid-column: span 2;
              }
            `}
          >
            <p
              css={`
                margin: 0;

                color: ${theme.colors.primary};
                font-weight: ${theme.fontWeights.medium};

                :after {
                  content: '*';
                }
              `}
            >
              Êtes-vous nerveux face aux traitements dentaires?
            </p>

            <div>
              <Slider
                min={0}
                max={10}
                handleAlignment='contain'
                name='nervousAgainstDentalTreatments'
                value={Number(values.nervousAgainstDentalTreatments)}
                onChange={(e) => {
                  setFieldValue('nervousAgainstDentalTreatments', e);
                }}
              />
            </div>

            {errors.nervousAgainstDentalTreatments &&
              touched.nervousAgainstDentalTreatments && (
                <div
                  css={`
                    grid-column: 1 / -1;
                  `}
                >
                  <FormHelperText
                    id={nervousAgainstDentalTreatmentsHelperTextId}
                    error
                  >
                    {errors.nervousAgainstDentalTreatments}
                  </FormHelperText>
                </div>
              )}
          </div>
        </div>
      </Fieldset>

      <p
        css={`
          margin-top: ${rem(32)};

          color: ${theme.colors.wildSand800};
        `}
      >
        * Champs obligatoires
      </p>
    </Stack>
  );
};

export default AllergiesLifeHabitsStep;

// Vendors
import React from 'react';
import { useFormikContext } from 'formik';
import { useStaticQuery, graphql } from 'gatsby';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

import '../../../../components/Form/Validations/yupPhoneValidation';
import '../../../../components/Form/Validations/yupPostalCodeValidation';

import { greaterThan } from '../../../../utils/mediaQuery';
import beforeMaskedStateChange from '../../../../utils/beforeMaskedStateChange';
import randomString from '../../../../utils/randomString';

import TextInputField from '../../../../components/TextInputField';
import Select from '../../../../components/Select';
import * as SelectUtils from '../../../../components/Select/Select.utils';
import SelectField from '../../../../components/SelectField';

import { Grid, BiggerFormLabel } from './GeneralInformationStep.styles';

import {
  Stack,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../../../../components/Form/Form.styles';
import FormHelperText from '../../../../components/FormHelperText';

export const title = 'Informations générales';

export const validationSchema = Yup.object().shape({
  lastName: Yup.string()
    .required('Insérez votre nom')
    .min(2, 'Le nom inséré est trop court')
    .max(50, 'Le nom inséré est trop long'),
  firstName: Yup.string()
    .required('Insérez votre prénom')
    .min(2, 'Le prénom inséré est trop court')
    .max(50, 'Le prénom inséré est trop long'),
  address: Yup.string().required('Insérez votre adresse postale'),
  city: Yup.string().required('Insérez votre ville de résidence'),
  postalCode: Yup.string()
    .required('Insérez votre code postal')
    .postalCode('CA', 'Insérez un code postal valide'),
  province: Yup.string().required('Sélectionnez votre province'),
  phone: Yup.string()
    .required('Insérez votre numéro de téléphone')
    .phone('CA', 'Insérez un numéro de téléphone valide'),
  cell: Yup.string().phone('CA', 'Insérez un numéro de cellulaire valide'),
  email: Yup.string()
    .required('Insérez votre adresse courriel')
    .email('Insérez une adresse courriel valide'),
  clinique: Yup.string().required('Selectionnez une clinique'),
});

export const initialValues = {
  lastName: '',
  firstName: '',
  address: '',
  city: '',
  postalCode: '',
  province: '',
  phone: '',
  cell: '',
  email: '',
  communicationMode: '',
  clinique: '',
  heardAboutUs: '',
};

const communicationMode = ['Courriel', 'Téléphone', 'Texto'];

const heardAboutUs = ['Internet', 'Bouche à oreilles', 'Autre'];

const provinces = [
  'Alberta',
  'Colombie-Britannique',
  'Île-du-Prince-Édouard',
  'Manitoba',
  'Nouveau-Brunswick',
  'Nouvelle-Écosse',
  'Nunavut',
  'Ontario',
  'Québec',
  'Saskatchewan',
  'Terre-Neuve-et-Labrador',
  'Territoires du Nord-Ouest',
  'Yukon',
];

const query = graphql`
  query {
    locations: allSanityLocation(sort: { fields: sort, order: ASC }) {
      edges {
        node {
          name
        }
      }
    }
  }
`;

const GeneralInformationStep = () => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormikContext();

  const {
    locations: { edges: cliniquesFromQuery = [] },
  } = useStaticQuery(query);

  const cliniques = cliniquesFromQuery.map(({ node }) => {
    return node.name;
  });

  const selectCliniqueId = randomString();
  const selectHeardAboutUs = randomString();

  return (
    <Stack>
      <Fieldset>
        <Legend>Informations générales</Legend>

        <FieldsetContent>
          <Stack>
            <Grid>
              <TextInputField
                label='Nom*'
                name='lastName'
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastName && touched.lastName}
                helperText={touched.lastName ? errors.lastName : ''}
              />

              <TextInputField
                label='Prénom*'
                name='firstName'
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstName && touched.firstName}
                helperText={touched.firstName ? errors.firstName : ''}
              />

              <TextInputField
                label='Adresse*'
                name='address'
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.address && touched.address}
                helperText={touched.address ? errors.address : ''}
              />

              <TextInputField
                label='Ville*'
                name='city'
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.city && touched.city}
                helperText={touched.city ? errors.city : ''}
              />

              <InputMask
                mask='a9a 9a9'
                beforeMaskedStateChange={beforeMaskedStateChange}
                name='postalCode'
                value={values.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.postalCode && touched.postalCode}
                disabled={false}
              >
                <TextInputField
                  label='Code postal*'
                  helperText={touched.postalCode ? errors.postalCode : ''}
                />
              </InputMask>

              <SelectField
                label='Province*'
                name='province'
                value={values.province}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.province && touched.province}
                helperText={touched.province ? errors.province : ''}
              >
                {SelectUtils.fillPlaceholder('Sélectionnez').concat(
                  SelectUtils.fillOptions(provinces)
                )}
              </SelectField>

              <InputMask
                mask='(999) 999-9999'
                beforeMaskedStateChange={beforeMaskedStateChange}
                type='phone'
                name='phone'
                value={values.phone}
                error={errors.phone && touched.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={false}
              >
                <TextInputField
                  label='Téléphone principal*'
                  helperText={touched.phone ? errors.phone : ''}
                />
              </InputMask>

              <InputMask
                mask='(999) 999-9999'
                beforeMaskedStateChange={beforeMaskedStateChange}
                type='cell'
                name='cell'
                value={values.cell}
                error={errors.cell && touched.cell}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={false}
              >
                <TextInputField
                  label='Téléphone cellulaire'
                  helperText={touched.cell ? errors.cell : ''}
                />
              </InputMask>

              <TextInputField
                label='Courriel*'
                name='email'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                helperText={touched.email ? errors.email : ''}
              />

              <SelectField
                label='Mode de communication favori'
                name='communicationMode'
                value={values.communicationMode}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {SelectUtils.fillPlaceholder('Sélectionnez').concat(
                  SelectUtils.fillOptions(communicationMode)
                )}
              </SelectField>
            </Grid>

            <div
              css={`
                display: grid;
                grid-template-columns: 1fr;

                grid-gap: 32px;

                ${greaterThan(1146)} {
                  grid-gap: 96px;
                  grid-template-columns: repeat(
                    auto-fit,
                    minmax(min(300px, 100%), 1fr)
                  );
                }
              `}
            >
              <BiggerFormLabel htmlFor={selectCliniqueId}>
                <span
                  css={`
                    ${greaterThan(795)} {
                      grid-row: span 2;
                    }
                  `}
                >
                  À quelle clinique souhaitez-vous obtenir votre rendez-vous?*
                </span>

                <Select
                  id={selectCliniqueId}
                  aria-describedby={`${selectCliniqueId}-helpText`}
                  name='clinique'
                  value={values.clinique}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.clinique && touched.clinique}
                  css={`
                    ${greaterThan(1440)} {
                      grid-row: 3;
                    }
                  `}
                >
                  {SelectUtils.fillPlaceholder('Sélectionnez').concat(
                    SelectUtils.fillOptions(cliniques)
                  )}
                </Select>

                {errors.clinique && touched.clinique && (
                  <FormHelperText id={`${selectCliniqueId}-helpText`} error>
                    {errors.clinique}
                  </FormHelperText>
                )}
              </BiggerFormLabel>

              <BiggerFormLabel htmlFor={selectHeardAboutUs}>
                <span>Comment avez-vous entendu de nous?</span>

                <Select
                  id={selectHeardAboutUs}
                  name='heardAboutUs'
                  value={values.heardAboutUs}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  css={`
                    ${greaterThan(1440)} {
                      grid-row: 3;
                    }
                  `}
                >
                  {SelectUtils.fillPlaceholder('Sélectionnez').concat(
                    SelectUtils.fillOptions(heardAboutUs)
                  )}
                </Select>
              </BiggerFormLabel>
            </div>

            <p>* Champs obligatoires</p>
          </Stack>
        </FieldsetContent>
      </Fieldset>
    </Stack>
  );
};

export default GeneralInformationStep;

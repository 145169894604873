// Vendors
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { rem } from 'polished';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

import '../../../../components/Form/Validations/yupPhoneValidation';

import beforeMaskedStateChange from '../../../../utils/beforeMaskedStateChange';

import TextInputField from '../../../../components/TextInputField';
import TextAreaField from '../../../../components/TextAreaField';
import * as SelectUtils from '../../../../components/Select/Select.utils';
import SelectField from '../../../../components/SelectField';
import RadioButton from '../../../../components/RadioButton';

import {
  Grid,
  Stack,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../../../../components/Form/Form.styles';

export const title = 'Informations personnelles';

export const validationSchema = Yup.object().shape({
  dateBirth: Yup.date()
    .required('Insérez votre date de naissance')
    .typeError('Insérez une date valide'),
  examPurpose: Yup.string().required('Insérez la raison de votre visite'),
  gender: Yup.string().required('Indiquez votre sexe'),
  height: Yup.string().required('Indiquez votre taille'),
  weight: Yup.string().required('Indiquez votre poids'),
});

export const initialValues = {
  dateBirth: '',
  occupation: '',
  motherName: '',
  fatherName: '',
  height: '',
  weight: '',
  incomeSecurityProvider: '',
  examPurpose: '',
  gender: '',
};

const incomeSecurityProviders = ['Oui', 'Non'];

const genders = ['Féminin', 'Masculin', 'Non-binaire'];

const PersonalInformationStep = () => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();
  const [heightUnit, setHeightUnit] = useState('imperial');
  const [weightUnit, setWeightUnit] = useState('metric');

  const heightInputRef = React.createRef();
  const weightInputRef = React.createRef();

  let { height, weight } = values;

  const heightMask =
    heightUnit === 'imperial'
      ? [/[0-9]/, "'", /[0-9]?/, /[0-9]/, '"']
      : [/[0-9]?/, /[0-9]?/, /[0-9]/, 'cm'];

  const cmToFeet = (value) => {
    let feet = Math.floor((value * 0.3937008) / 12);
    let inchs = Math.round((value * 0.3937008) % 12);

    feet = feet > 0 ? feet : '';
    inchs = inchs > 0 ? inchs : '';

    return `${feet}${inchs}`;
  };

  if (height !== '' && heightUnit === 'imperial') {
    height = cmToFeet(height);
  }

  if (height !== '' && heightUnit === 'metric') {
    height = String(`${Math.round(height)}`);
  }

  if (weight !== '') {
    weight = Math.round(weightUnit === 'metric' ? weight : weight * 2.204623);
  }

  const handleHeightChange = (e) => {
    let { value } = e.target;

    if (heightUnit === 'imperial') {
      const foot = value.substring(0, 1);
      const inches = value.substring(
        value.lastIndexOf("'") + 1,
        value.lastIndexOf('"')
      );

      value = (Number(foot) * 12 + Number(inches)) / 0.3937008;
    }

    if (heightUnit === 'metric') {
      value = value.substring(0, value.lastIndexOf('c'));
    }

    value = value > 0 ? String(value) : '';

    setFieldValue('height', value);
  };

  const handleHeightUnitChange = (e) => {
    setHeightUnit(e.target.value);

    heightInputRef.current.focus();
  };

  const handleWeightChange = (e) => {
    const input = e.target.value;

    const value = weightUnit === 'metric' ? input : input / 2.204623;

    setFieldValue('weight', value);
  };

  const handleWeightUnitChange = (e) => {
    setWeightUnit(e.target.value);

    weightInputRef.current.focus();
  };

  return (
    <Stack>
      <Fieldset>
        <Legend>{title}</Legend>

        <FieldsetContent>
          <Grid>
            <InputMask
              mask='9999/99/99'
              beforeMaskedStateChange={beforeMaskedStateChange}
              name='dateBirth'
              value={values.dateBirth}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.dateBirth && touched.dateBirth}
              disabled={false}
              alwaysShowMask
            >
              <TextInputField
                label='Votre date de naissance* AAAA/MM/JJ'
                helperText={touched.dateBirth ? errors.dateBirth : ''}
              />
            </InputMask>

            <SelectField
              label='Sexe*'
              name='gender'
              value={values.gender}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.gender && touched.gender}
              helperText={touched.gender ? errors.gender : ''}
              css={`
                max-width: 220px;
              `}
            >
              {SelectUtils.fillPlaceholder('Sélectionnez').concat(
                SelectUtils.fillOptions(genders)
              )}
            </SelectField>

            <TextInputField
              label='Prénom et nom de la mère'
              name='motherName'
              value={values.motherName}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextInputField
              label='Prénom et nom du père'
              name='fatherName'
              value={values.fatherName}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <div
              css={`
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                grid-gap: ${rem(32)};
              `}
            >
              <InputMask
                mask={heightMask}
                name='height'
                value={height}
                onChange={handleHeightChange}
                onBlur={handleBlur}
                disabled={false}
                maskPlaceholder=' '
              >
                <TextInputField
                  label='Taille*'
                  inputRef={heightInputRef}
                  error={errors.height && touched.height}
                  helperText={touched.height ? errors.height : ''}
                  css={`
                    > input {
                      max-width: 105px;
                    }
                  `}
                />
              </InputMask>

              <div>
                <RadioButton
                  name='heightUnit'
                  value='imperial'
                  checked={heightUnit === 'imperial'}
                  onChange={handleHeightUnitChange}
                  onBlur={handleBlur}
                  css={`
                    margin-right: 48px;
                  `}
                >
                  Pieds
                </RadioButton>

                <RadioButton
                  name='heightUnit'
                  value='metric'
                  checked={heightUnit === 'metric'}
                  onChange={handleHeightUnitChange}
                  onBlur={handleBlur}
                >
                  Centimètre
                </RadioButton>
              </div>
            </div>

            <div
              css={`
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                grid-gap: ${rem(32)};
              `}
            >
              <TextInputField
                inputRef={weightInputRef}
                label='Poids*'
                name='weight'
                value={weight}
                onChange={handleWeightChange}
                onBlur={handleBlur}
                maxLength='3'
                error={errors.weight && touched.weight}
                helperText={touched.weight ? errors.weight : ''}
                css={`
                  > input {
                    max-width: 105px;
                  }
                `}
              />

              <div>
                <RadioButton
                  name='weightUnit'
                  value='imperial'
                  checked={weightUnit === 'imperial'}
                  onChange={handleWeightUnitChange}
                  onBlur={handleBlur}
                  css={`
                    margin-right: 48px;
                  `}
                >
                  Livres
                </RadioButton>

                <RadioButton
                  name='weightUnit'
                  value='metric'
                  checked={weightUnit === 'metric'}
                  onChange={handleWeightUnitChange}
                  onBlur={handleBlur}
                >
                  Kilos
                </RadioButton>
              </div>
            </div>

            <TextInputField
              label='Occupation'
              name='occupation'
              value={values.occupation}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <SelectField
              label='Êtes-vous prestataire de sécurité de revenu?'
              name='incomeSecurityProvider'
              value={values.incomeSecurityProvider}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {SelectUtils.fillPlaceholder('Sélectionnez une option').concat(
                SelectUtils.fillOptions(incomeSecurityProviders)
              )}
            </SelectField>

            <TextAreaField
              label='Quelle est la raison de votre visite?*'
              name='examPurpose'
              rows='6'
              value={values.examPurpose}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.examPurpose && touched.examPurpose}
              helperText={touched.examPurpose ? errors.examPurpose : ''}
            />
          </Grid>

          <p>* Champs obligatoires</p>
        </FieldsetContent>
      </Fieldset>
    </Stack>
  );
};

export default PersonalInformationStep;

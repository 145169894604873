// vendors
import * as Yup from 'yup';

const regex = /\D{4} \d{4} \d{4}$/;

function yupHealthInsuranceValidation(message) {
  return this.matches(regex, message).test(
    'healthInsurance',
    message,
    (value) => {
      if (!regex.test(value)) return false;

      return true;
    }
  );
}

Yup.addMethod(Yup.string, 'healthInsurance', yupHealthInsuranceValidation);

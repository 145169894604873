// Vendors
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useNotificationsCenter } from '../contexts/notificationsCenterContext';
import { bannerType } from '../components/NotificationsCenter/Banner';

import colors from '../styles/colors';

import RefinedLayout from '../components/RefinedLayout';
import Wizard from '../components/Wizard';

import IntroStep from '../views/MedicalQuestionnaireForm/Steps/IntroStep';

import GeneralInformationStep, {
  title as titleGeneralInformation,
  initialValues as initialValuesGeneralInformation,
  validationSchema as validationSchemaGeneralInformation,
} from '../views/MedicalQuestionnaireForm/Steps/GeneralInformationStep';

import PersonalInformationStep, {
  title as titlePersonalInformation,
  initialValues as initialValuesPersonalInformation,
  validationSchema as validationSchemaPersonalInformation,
} from '../views/MedicalQuestionnaireForm/Steps/PersonalInformationStep';

import MedicalInformationStep, {
  title as titleMedicalInformation,
  initialValues as initialValuesMedicalInformation,
  validationSchema as validationSchemaMedicalInformation,
} from '../views/MedicalQuestionnaireForm/Steps/MedicalInformationStep';

import MedicalHistoryPartOneStep, {
  title as titleMedicalHistoryPartOne,
  initialValues as initialValuesMedicalHistoryPartOne,
  validationSchema as validationSchemaMedicalHistoryPartOne,
} from '../views/MedicalQuestionnaireForm/Steps/MedicalHistoryPartOneStep';

import MedicalHistoryPartTwoStep, {
  title as titleMedicalHistoryPartTwo,
  initialValues as initialValuesMedicalHistoryPartTwo,
  validationSchema as validationSchemaMedicalHistoryPartTwo,
} from '../views/MedicalQuestionnaireForm/Steps/MedicalHistoryPartTwoStep';

import AllergiesLifeHabitsStep, {
  title as titleAllergiesLifeHabits,
  initialValues as initialValuesAllergiesLifeHabits,
  validationSchema as validationSchemaAllergiesLifeHabits,
} from '../views/MedicalQuestionnaireForm/Steps/AllergiesLifeHabitsStep';

import ConfirmInformationStep, {
  title as titleConfirmInfo,
  initialValues as initialValuesConfirmInfo,
  validationSchema as validationSchemaConfirmInfo,
} from '../views/MedicalQuestionnaireForm/Steps/ConfirmInformationStep';

const API_URL = process.env.GATSBY_API_URL || 'https://api.maxilloquebec.com';

const QuestionnaireMedicalPage = ({ navigate }) => {
  const notificationIdRef = useRef(null);

  const { addNotification, removeNotification } = useNotificationsCenter();

  const handleSubmit = async (values, actions) => {
    if (notificationIdRef.current) {
      removeNotification(notificationIdRef.current);
    }

    try {
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      const myInit = {
        method: 'POST',
        mode: 'cors',
        body: formData,
      };

      const response = await fetch(
        `${API_URL}/v1/form/medical-questionnaire`,
        myInit
      ).then((res) => {
        if (res.status > 400) {
          throw new Error(res.statusText);
        }

        return res.json();
      });

      if (response.success) {
        notificationIdRef.current = addNotification(
          'Votre formulaire a bien été envoyé. Nous prendrons contact avec vous prochainement.',
          {
            autoDismiss: true,
            type: bannerType.success,
          }
        );

        navigate('/');
      }

      if (response.inner && response.inner.length > 0) {
        response.inner.forEach(({ message, path }) =>
          actions.setFieldError(path, message)
        );

        const notificationMessage =
          response.inner.length > 1
            ? 'Il y a plusieurs champs incomplets.'
            : response.message;

        notificationIdRef.current = addNotification(notificationMessage, {
          autoDismiss: true,
          type: bannerType.error,
        });
      }

      actions.setSubmitting(false);
    } catch (error) {
      addNotification(
        'Une erreur s’est produite. Le formulaire n’a pu être envoyé. Veuillez essayer de nouveau.',
        {
          autoDismiss: true,
          type: bannerType.error,
        }
      );

      // window.Sentry.captureException(error);

      console.error(error);
    }
  };

  return (
    <RefinedLayout backgroundColor={colors.mineShaft}>
      <Wizard formTitle='Questionnaire médical' onSubmit={handleSubmit}>
        <IntroStep />

        <GeneralInformationStep
          title={titleGeneralInformation}
          initialValues={initialValuesGeneralInformation}
          validationSchema={validationSchemaGeneralInformation}
        />

        <PersonalInformationStep
          title={titlePersonalInformation}
          initialValues={initialValuesPersonalInformation}
          validationSchema={validationSchemaPersonalInformation}
        />

        <MedicalInformationStep
          title={titleMedicalInformation}
          initialValues={initialValuesMedicalInformation}
          validationSchema={validationSchemaMedicalInformation}
        />

        <MedicalHistoryPartOneStep
          title={titleMedicalHistoryPartOne}
          initialValues={initialValuesMedicalHistoryPartOne}
          validationSchema={validationSchemaMedicalHistoryPartOne}
        />

        <MedicalHistoryPartTwoStep
          title={titleMedicalHistoryPartTwo}
          initialValues={initialValuesMedicalHistoryPartTwo}
          validationSchema={validationSchemaMedicalHistoryPartTwo}
        />

        <AllergiesLifeHabitsStep
          title={titleAllergiesLifeHabits}
          initialValues={initialValuesAllergiesLifeHabits}
          validationSchema={validationSchemaAllergiesLifeHabits}
        />

        <ConfirmInformationStep
          title={titleConfirmInfo}
          initialValues={initialValuesConfirmInfo}
          validationSchema={validationSchemaConfirmInfo}
        />
      </Wizard>
    </RefinedLayout>
  );
};

QuestionnaireMedicalPage.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default QuestionnaireMedicalPage;

// Vendors
import React from 'react';
import { useFormikContext } from 'formik';
import { rem } from 'polished';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

import '../../../../components/Form/Validations/yupDateFormatValidation';

import styled from 'styled-components';
import theme from '../../../../styles/theme';
import beforeMaskedStateChange from '../../../../utils/beforeMaskedStateChange';

import TextInputField from '../../../../components/TextInputField';
import Checkbox from '../../../../components/Checkbox';

import {
  Stack,
  Fieldset,
  Legend,
  FieldsetContent,
} from '../../../../components/Form/Form.styles';
import RadioButtonGroup from '../../../../components/RadioButtonGroup';
import { lessThan, greaterThan } from '../../../../utils/mediaQuery';
import FormHelperText from '../../../../components/FormHelperText';

export const title = 'Historique médical';

export const validationSchema = Yup.object().shape({
  takingMedications: Yup.string().required('Sélectionnez une réponse'),
  medications: Yup.string().when('takingMedications', {
    is: (val) => val === 'true',
    then: Yup.string().required('Inscrivez votre réponse'),
  }),
  takingNaturalProducts: Yup.string().required('Sélectionnez une réponse'),
  naturalProducts: Yup.string().when('takingNaturalProducts', {
    is: (val) => val === 'true',
    then: Yup.string().required('Inscrivez votre réponse'),
  }),
  hadSurgicalInterventions: Yup.string().required('Sélectionnez une réponse'),
  surgicalInterventions: Yup.string().when('hadSurgicalInterventions', {
    is: (val) => val === 'true',
    then: Yup.string().required('Inscrivez votre réponse'),
  }),
  takingOralContraceptives: Yup.string().required('Sélectionnez une réponse'),
  thinkingOrBeingPregnant: Yup.string().required('Sélectionnez une réponse'),
  breastFeeding: Yup.string().required('Sélectionnez une réponse'),
  takingInjectionMedication: Yup.string().required('Sélectionnez une réponse'),
  injectionMedications: Yup.string().when('takingInjectionMedication', {
    is: (val) => val === 'true',
    then: Yup.string().required('Inscrivez votre réponse'),
  }),
  takingBiphosphonate: Yup.string().required('Sélectionnez une réponse'),
  dateTakingBiphosphonate: Yup.date()
    .dateFormat('YYYY/MM', true, 'Insérez une date valide')
    .when('takingBiphosphonate', {
      is: (val) => val === 'true',
      then: Yup.date()
        .dateFormat('YYYY/MM', true, 'Insérez une date valide')
        .required('Inscrivez votre réponse'),
    }),
  specificMedications: Yup.array()
    .required('Sélectionnez une réponse')
    .min(1, 'Sélectionnez une réponse'),
});

export const initialValues = {
  takingMedications: '',
  medications: '',
  takingNaturalProducts: '',
  naturalProducts: '',
  hadSurgicalInterventions: '',
  surgicalInterventions: '',
  takingOralContraceptives: '',
  thinkingOrBeingPregnant: '',
  breastFeeding: '',
  specificMedications: '',
  takingInjectionMedication: '',
  injectionMedications: '',
  takingBiphosphonate: '',
  dateTakingBiphosphonate: '',
};

const specificMedicationsList = [
  'Anti-plaquettaires (Plavix, Éliquis, Pradax)',
  'Cortisone',
  'Antigoagulant (Coumadin, Pradax, Éliquis)',
  'Chimiothérapie',
  'Non',
];

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  ${lessThan(650)} {
    grid-column: span 1;
  }
`;

const MedicalHistoryPartOneStep = () => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();

  const handleCheckboxChange = (e) => {
    const {
      target: { name, value },
    } = e;

    let newValues = [...values[name]];

    const index = newValues.indexOf(value);

    if (index > -1) {
      newValues = [...newValues.filter((el) => el !== value)];
    } else {
      newValues.push(value);
    }

    setFieldValue(name, newValues);
  };

  return (
    <Stack>
      <Fieldset>
        <Legend>Historique médical</Legend>

        <FieldsetContent>
          <Stack>
            <div
              css={`
                display: grid;
                grid-template-columns: 1fr;
                grid-column-gap: ${rem(96)};
                grid-row-gap: ${rem(32)};
                align-items: start;
                justify-content: center;

                ${greaterThan(650)} {
                  grid-template-columns: repeat(
                    auto-fit,
                    minmax(200px, max-content)
                  );
                }
              `}
            >
              <StyledRadioButtonGroup
                legend='Prenez-vous des médicaments?'
                name='takingMedications'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.takingMedications}
                error={
                  errors.takingMedications && touched.takingMedications
                    ? errors.takingMedications
                    : undefined
                }
                options={[
                  { label: 'Oui', value: 'true' },
                  { label: 'Non', value: 'false' },
                ]}
                required
                inline
              >
                {values.takingMedications === 'true' && (
                  <TextInputField
                    label='Si oui, lesquels?'
                    name='medications'
                    value={values.medications}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.medications && touched.medications}
                    helperText={touched.medications ? errors.medications : ''}
                  />
                )}
              </StyledRadioButtonGroup>

              <StyledRadioButtonGroup
                legend='Prenez-vous des produits naturels?'
                name='takingNaturalProducts'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.takingNaturalProducts}
                error={
                  errors.takingNaturalProducts && touched.takingNaturalProducts
                    ? errors.takingNaturalProducts
                    : undefined
                }
                options={[
                  { label: 'Oui', value: 'true' },
                  { label: 'Non', value: 'false' },
                ]}
                required
                inline
              >
                {values.takingNaturalProducts === 'true' && (
                  <TextInputField
                    label='Si oui, lesquels?'
                    name='naturalProducts'
                    value={values.naturalProducts}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.naturalProducts && touched.naturalProducts}
                    helperText={
                      touched.naturalProducts ? errors.naturalProducts : ''
                    }
                  />
                )}
              </StyledRadioButtonGroup>

              <StyledRadioButtonGroup
                legend='Avez-vous déjà subi une intervention chirurgicale?'
                name='hadSurgicalInterventions'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.hadSurgicalInterventions}
                error={
                  errors.hadSurgicalInterventions &&
                  touched.hadSurgicalInterventions
                    ? errors.hadSurgicalInterventions
                    : undefined
                }
                options={[
                  { label: 'Oui', value: 'true' },
                  { label: 'Non', value: 'false' },
                ]}
                required
                inline
              >
                {values.hadSurgicalInterventions === 'true' && (
                  <TextInputField
                    label='Si oui, laquelle?'
                    name='surgicalInterventions'
                    value={values.surgicalInterventions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.surgicalInterventions &&
                      touched.surgicalInterventions
                    }
                    helperText={
                      touched.surgicalInterventions
                        ? errors.surgicalInterventions
                        : ''
                    }
                  />
                )}
              </StyledRadioButtonGroup>

              <StyledRadioButtonGroup
                legend='Prenez-vous des contraceptifs oraux?'
                name='takingOralContraceptives'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.takingOralContraceptives}
                error={
                  errors.takingOralContraceptives &&
                  touched.takingOralContraceptives
                    ? errors.takingOralContraceptives
                    : undefined
                }
                options={[
                  { label: 'Oui', value: 'true' },
                  { label: 'Non', value: 'false' },
                ]}
                required
                inline
              />

              <StyledRadioButtonGroup
                legend='Êtes-vous, ou croyez-vous être enceinte?'
                name='thinkingOrBeingPregnant'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.thinkingOrBeingPregnant}
                error={
                  errors.thinkingOrBeingPregnant &&
                  touched.thinkingOrBeingPregnant
                    ? errors.thinkingOrBeingPregnant
                    : undefined
                }
                options={[
                  { label: 'Oui', value: 'true' },
                  { label: 'Non', value: 'false' },
                ]}
                required
                inline
              />

              <StyledRadioButtonGroup
                legend='Allaitez-vous?'
                name='breastFeeding'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.breastFeeding}
                error={
                  errors.breastFeeding && touched.breastFeeding
                    ? errors.breastFeeding
                    : undefined
                }
                options={[
                  { label: 'Oui', value: 'true' },
                  { label: 'Non', value: 'false' },
                ]}
                required
                inline
              />

              <StyledRadioButtonGroup
                legend='Prenez-vous des médicaments par injection?'
                name='takingInjectionMedication'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.takingInjectionMedication}
                error={
                  errors.takingInjectionMedication &&
                  touched.takingInjectionMedication
                    ? errors.takingInjectionMedication
                    : undefined
                }
                options={[
                  { label: 'Oui', value: 'true' },
                  { label: 'Non', value: 'false' },
                ]}
                required
                inline
              >
                {values.takingInjectionMedication === 'true' && (
                  <TextInputField
                    label='Si oui, lesquels?'
                    name='injectionMedications'
                    value={values.injectionMedications}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.injectionMedications &&
                      touched.injectionMedications
                    }
                    helperText={
                      touched.injectionMedications
                        ? errors.injectionMedications
                        : ''
                    }
                  />
                )}
              </StyledRadioButtonGroup>

              <StyledRadioButtonGroup
                legend='Prenez-vous des biphosphonates ou agents anti-résorption (Fosamax, Prolia)?'
                name='takingBiphosphonate'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.takingBiphosphonate}
                error={
                  errors.takingBiphosphonate && touched.takingBiphosphonate
                    ? errors.takingBiphosphonate
                    : undefined
                }
                options={[
                  { label: 'Oui', value: 'true' },
                  { label: 'Non', value: 'false' },
                ]}
                required
                inline
              >
                {values.takingBiphosphonate === 'true' && (
                  <InputMask
                    mask='9999/99'
                    beforeMaskedStateChange={beforeMaskedStateChange}
                    name='dateTakingBiphosphonate'
                    value={values.dateTakingBiphosphonate}
                    error={
                      errors.dateTakingBiphosphonate &&
                      touched.dateTakingBiphosphonate
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={false}
                  >
                    <TextInputField
                      label='Si oui, depuis quand? AAAA/MM'
                      helperText={
                        touched.dateTakingBiphosphonate
                          ? errors.dateTakingBiphosphonate
                          : ''
                      }
                    />
                  </InputMask>
                )}
              </StyledRadioButtonGroup>

              <Fieldset
                css={`
                  ${greaterThan(650)} {
                    grid-column: span 2;
                  }
                `}
              >
                <legend
                  css={`
                    color: ${theme.colors.primary};
                    font-weight: ${theme.fontWeights.medium};
                  `}
                >
                  Avez-vous déjà pris les médicaments suivants?*
                </legend>

                <div
                  css={`
                    display: grid;
                    grid-gap: ${rem(32)};

                    margin-top: ${rem(32)};
                  `}
                >
                  {specificMedicationsList.map((specificMedication) => (
                    <Checkbox
                      key={specificMedication}
                      name='specificMedications'
                      value={specificMedication}
                      onChange={handleCheckboxChange}
                      onBlur={handleBlur}
                      checked={values.specificMedications.includes(
                        specificMedication
                      )}
                      css={`
                        font-size: ${rem(24)};
                      `}
                    >
                      {specificMedication}
                    </Checkbox>
                  ))}
                </div>
                {errors.specificMedications && (
                  <FormHelperText error>
                    {errors.specificMedications}
                  </FormHelperText>
                )}
              </Fieldset>
            </div>
            <p
              css={`
                margin-top: ${rem(24)};
              `}
            >
              * Champs obligatoires
            </p>
          </Stack>
        </FieldsetContent>
      </Fieldset>
    </Stack>
  );
};

export default MedicalHistoryPartOneStep;

import React from 'react';

import SelectOption from './SelectOption';

export const fillPlaceholder = (text) => [
  <SelectOption key={text} disabled hidden value=''>
    {text}
  </SelectOption>,
];

export const fillOptions = (opts) => {
  return opts.map((option, index) => (
    <SelectOption key={String(index + 1)} value={option}>
      {option}
    </SelectOption>
  ));
};

// Vendors
import React from 'react';
import { useFormikContext } from 'formik';
import { css } from 'styled-components';
import { rem } from 'polished';
import * as Yup from 'yup';

import '../../../../components/Form/Validations/yupDateFormatValidation';

import theme from '../../../../styles/theme';

import Checkbox, { CheckboxStyles } from '../../../../components/Checkbox';
import TextArea from '../../../../components/TextArea';

import {
  Stack,
  Fieldset,
  Legend,
  FieldsetContent,
} from '../../../../components/Form/Form.styles';
import colors from '../../../../styles/colors';
import FormHelperText from '../../../../components/FormHelperText';

export const title = 'Historique médical (suite)';

export const initialValues = {
  disorders: [],
  furtherHealthInformation: '',
};

const heartDisorders = [
  'Troubles cardiaques',
  'Angine et infarctus',
  'Souffle cardiaque',
  'Anomalie valve cardiaque',
];

const bloodDisorders = [
  'Problèmes sanguins',
  'Anémie',
  'Hémophilie',
  'Hémorragie durant chirurgie dentaire ou autre',
  'Saignements prolongés / Sang clair',
  'Transfusions sanguines',
  'Thrombophlébites (caillots)',
];

const respiratoryDisorders = [
  'Problèmes respiratoires',
  'Asthme ou bronchite chronique',
  'Apnée du sommeil',
  'Difficultés respiratoires',
  'Embolie pulmonaire',
];

const otherDisorders = [
  'Fièvre rhumatismale',
  'Diabète',
  'Radiothérapie',
  'Troubles thyroïdiens',
  'Ulcères estomac',
  'Arthrite',
  'Herpès labial',
  'Tuberculose',
  'Ostéoporose',
  'Maladies transmises sexuellement',
  'Haute pression',
  'Ronflements',
  'Cancer',
  'Problèmes de foie (hépatite A, B, C, cirrohose, etc.)',
  'Sida / VIH',
  'Troubles de santé mentale',
  'Troubles nerveux',
  'Épilepsie ou convulsions',
  'Aucune',
];

export const validationSchema = Yup.object().shape({
  disorders: Yup.array()
    .required('Sélectionnez une réponse')
    .min(1, 'Sélectionnez une réponse'),
});

const MedicalHistoryPartTwoStep = () => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    setFieldValue,
  } = useFormikContext();

  console.log(errors);

  const handleCheckboxChange = (e) => {
    const {
      target: { name, value },
    } = e;

    let newValues = [...values[name]];

    const index = newValues.indexOf(value);

    if (index > -1) {
      newValues = [...newValues.filter((el) => el !== value)];
    } else {
      newValues.push(value);
    }

    setFieldValue(name, newValues);
  };

  return (
    <Stack>
      <Fieldset>
        <Legend>Historique médical</Legend>

        <FieldsetContent>
          <Stack>
            <Fieldset>
              <legend
                css={`
                  color: ${theme.colors.primary};
                  font-weight: ${theme.fontWeights.medium};
                `}
              >
                Avez-vous souffert ou souffrez-vous d&apos;une des conditions
                médicales suivantes?*
              </legend>

              <div
                css={`
                  display: grid;
                  grid-column-gap: ${rem(96)};
                  grid-row-gap: ${rem(32)};
                  grid-template-columns: repeat(
                    auto-fill,
                    minmax(min(400px, 100%), 1fr)
                  );

                  margin-top: ${rem(32)};
                `}
              >
                <div
                  css={`
                    display: grid;
                    grid-row-gap: inherit;
                    grid-row: span 5;
                    grid-auto-rows: 1fr;
                    grid-template-rows: subgrid;

                    :after {
                      content: '';
                      display: block;
                    }
                  `}
                >
                  {heartDisorders.map((heartDisorder, index) => (
                    <Checkbox
                      key={heartDisorder}
                      name='disorders'
                      value={heartDisorder}
                      onChange={handleCheckboxChange}
                      onBlur={handleBlur}
                      checked={values.disorders.includes(heartDisorder)}
                      css={`
                        ${CheckboxStyles.rightPositionStyle};
                        font-size: ${rem(18)};

                        ${index === 0 &&
                        css`
                          color: ${colors.azure};
                        `}

                        ${index > 0 &&
                        css`
                          margin-left: ${rem(20)};
                          padding-left: ${rem(30)};

                          ::before {
                            position: absolute;
                            top: -4px;
                            left: 0;

                            width: 20px;
                            height: 20px;

                            border-bottom: 1px solid currentColor;

                            content: '';
                          }
                        `}
                      `}
                    >
                      {heartDisorder}
                    </Checkbox>
                  ))}
                </div>

                <div
                  css={`
                    display: grid;
                    grid-row-gap: inherit;
                    grid-row: span 7;
                    grid-auto-rows: 1fr;
                    grid-template-rows: subgrid;

                    :after {
                      content: '';
                      display: block;
                    }
                  `}
                >
                  {bloodDisorders.map((bloodDisorder, index) => (
                    <Checkbox
                      key={bloodDisorder}
                      name='disorders'
                      value={bloodDisorder}
                      onChange={handleCheckboxChange}
                      onBlur={handleBlur}
                      checked={values.disorders.includes(bloodDisorder)}
                      css={`
                        ${CheckboxStyles.rightPositionStyle};
                        font-size: ${rem(18)};

                        ${index === 0 &&
                        css`
                          color: ${colors.azure};
                        `}

                        ${index > 0 &&
                        css`
                          margin-left: ${rem(20)};
                          padding-left: ${rem(30)};

                          ::before {
                            position: absolute;
                            top: -4px;
                            left: 0;

                            width: 20px;
                            height: 20px;

                            border-bottom: 1px solid currentColor;

                            content: '';
                          }
                        `}
                      `}
                    >
                      {bloodDisorder}
                    </Checkbox>
                  ))}
                </div>

                <div
                  css={`
                    display: grid;
                    grid-row-gap: inherit;
                    grid-row: span 5;
                    grid-auto-rows: 1fr;
                    grid-template-rows: subgrid;

                    :after {
                      content: '';
                      display: block;
                    }
                  `}
                >
                  {respiratoryDisorders.map((respiratoryDisorder, index) => (
                    <Checkbox
                      key={respiratoryDisorder}
                      name='disorders'
                      value={respiratoryDisorder}
                      onChange={handleCheckboxChange}
                      onBlur={handleBlur}
                      checked={values.disorders.includes(respiratoryDisorder)}
                      css={`
                        ${CheckboxStyles.rightPositionStyle};
                        font-size: ${rem(18)};

                        ${index === 0 &&
                        css`
                          color: ${colors.azure};
                        `}

                        ${index > 0 &&
                        css`
                          margin-left: ${rem(20)};
                          padding-left: ${rem(30)};

                          ::before {
                            position: absolute;
                            top: -4px;
                            left: 0;

                            width: 20px;
                            height: 20px;

                            border-bottom: 1px solid currentColor;

                            content: '';
                          }
                        `}
                      `}
                    >
                      {respiratoryDisorder}
                    </Checkbox>
                  ))}
                </div>

                {otherDisorders.map((otherDisorder) => (
                  <Checkbox
                    key={otherDisorder}
                    name='disorders'
                    value={otherDisorder}
                    onChange={handleCheckboxChange}
                    onBlur={handleBlur}
                    checked={values.disorders.includes(otherDisorder)}
                    css={`
                      ${CheckboxStyles.rightPositionStyle};
                      font-size: ${rem(18)};
                    `}
                  >
                    {otherDisorder}
                  </Checkbox>
                ))}
              </div>

              {errors.disorders && (
                <FormHelperText error>{errors.disorders}</FormHelperText>
              )}
            </Fieldset>

            <Fieldset>
              <legend
                css={`
                  color: ${theme.colors.primary};
                  font-weight: ${theme.fontWeights.medium};
                `}
              >
                Désirez-vous ajouter des renseignements sur votre état de santé?
              </legend>

              <div
                css={`
                  ${theme.layouts.grid('300px', rem(32), 'auto-fill')}

                  margin-top: ${rem(32)};
                `}
              >
                <TextArea
                  name='furtherHealthInformation'
                  value={values.furtherHealthInformation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows='4'
                  css={`
                    grid-column: 1 / span 3;
                  `}
                />
              </div>
            </Fieldset>
          </Stack>
        </FieldsetContent>
      </Fieldset>
    </Stack>
  );
};

export default MedicalHistoryPartTwoStep;

// vendors
import React from 'react';
import PropTypes from 'prop-types';
import randomString from '../../utils/randomString';
import RadioButton from '../RadioButton';
import {
  Group,
  Legend,
  RadioGroup,
  ErrorWrapper,
  ChildrenWrapper,
} from './RadioButtonGroup.styles';
import FormHelperText from '../FormHelperText';

const RadioButtonGroup = ({
  legend,
  inline,
  name,
  value,
  options,
  error,
  onChange,
  onBlur,
  children,
  required,
  ...rest
}) => {
  const id = randomString();
  const errorId = randomString();

  return (
    <Group role='group' aria-labelledby={id} inline={inline} {...rest}>
      <Legend required={required} id={id}>
        {legend}
      </Legend>

      <RadioGroup>
        {options.map((option) => (
          <RadioButton
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
            onBlur={onBlur}
            describedBy={error ? errorId : undefined}
          >
            {option.label}
          </RadioButton>
        ))}
      </RadioGroup>

      {error && (
        <ErrorWrapper>
          <FormHelperText id={errorId} error>
            {error}
          </FormHelperText>
        </ErrorWrapper>
      )}

      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </Group>
  );
};

RadioButtonGroup.propTypes = {
  legend: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  required: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  inline: false,
  value: undefined,
  error: undefined,
  onChange: () => {},
  onBlur: () => {},
  children: undefined,
  required: false,
  options: [],
};

export default RadioButtonGroup;

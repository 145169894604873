// Vendors
import React from 'react';
import colors from '../../../../styles/colors';

const IntroStep = () => {
  return (
    <p style={{ color: colors.brownGrey }}>
      Cher patient, <br />
      Avant votre première visite à l’une de nos cliniques Maxillo Québec
      Lebourgneuf ou Lévis, nous vous invitons à remplir le questionnaire
      ci-après afin de nous permettre de mieux vous connaitre. Toutes les
      informations recueillies dans ce dit questionnaire seront destinées à
      compléter votre dossier médical. L’équipe de Maxillo Québec consultera vos
      informations de manière confidentielle, nous nous engageons à sécuriser
      vos données.
    </p>
  );
};

export default IntroStep;

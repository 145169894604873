import styled from 'styled-components';
import { rem } from 'polished';

import theme from '../../../../styles/theme';
import { greaterThan } from '../../../../utils/mediaQuery';
import responsiveStyle from '../../../../utils/responsiveStyle';

import FormLabel from '../../../../components/FormLabel';
import FormHelperText from '../../../../components/FormHelperText';

export const Grid = styled.div`
  ${theme.layouts.grid('460px', rem(32))}

  align-items: baseline;
`;

export const BiggerFormLabel = styled(FormLabel)`
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: 1fr;
  align-items: center;

  font-size: ${responsiveStyle(
    'fontSize',
    [16, 26],
    [795, theme.breakpoints[7]]
  )};

  line-height: 1;

  > div {
    margin-top: ${rem(8)};
  }

  ${greaterThan(795)} {
    grid-template-columns: 1fr 1fr;

    line-height: ${24 / theme.fontSizes.body[0]};

    > div {
      margin-top: 0;
    }

    > ${FormHelperText} {
      grid-column: 2;
    }
  }

  ${greaterThan(1146)} {
    grid-column-gap: 16px;
  }
`;

import * as Yup from 'yup';
import Moment from 'moment';

const invalidDate = new Date('');

function parseDateFromFormats(formats, parseStrict, message) {
  return this.transform((value, originalValue) => {
    if (this.isType(value)) return value;

    const val = Moment(originalValue, formats, parseStrict);

    return val.isValid() ? val.toDate() : invalidDate;
  }).typeError(message);
}

Yup.addMethod(Yup.date, 'dateFormat', parseDateFromFormats);

// Vendors
import React from 'react';
import { useFormikContext } from 'formik';
import { rem } from 'polished';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

import '../../../../components/Form/Validations/yupPhoneValidation';
import '../../../../components/Form/Validations/yupDateFormatValidation';
import '../../../../components/Form/Validations/yupHealthInsuranceValidation';

import beforeMaskedStateChange from '../../../../utils/beforeMaskedStateChange';
import theme from '../../../../styles/theme';

import TextInputField from '../../../../components/TextInputField';

import {
  Stack,
  Fieldset,
  FieldsetContent,
  Legend,
} from '../../../../components/Form/Form.styles';

export const title = 'Informations médicales';

export const validationSchema = Yup.object().shape({
  healthInsuranceNumber: Yup.string()
    .required("Insérez votre numéro d'assurance maladie")
    .healthInsurance("Insérez un numéro d'assurance maladie valide"),
  expirationDate: Yup.date()
    .required("Insérez une date d'expiration")
    .dateFormat('YYYY/MM', true, 'Insérez une date valide'),
});

export const initialValues = {
  healthInsuranceNumber: '',
  expirationDate: '',
  familyDoctorName: '',
  dentistName: '',
  pharmacyName: '',
};

const MedicalInformationStep = () => {
  const {
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormikContext();

  return (
    <Stack>
      <Fieldset>
        <Legend>Informations médicales</Legend>

        <FieldsetContent>
          <div
            css={`
              display: grid;
              grid-gap: ${rem(96)};
              grid-template-rows: auto auto;
            `}
          >
            <div
              css={`
                  ${theme.layouts.grid('400px', rem(32), 'auto-fill')}
                  grid-gap: ${rem(32)};
                  align-items: baseline;
                `}
            >
              <InputMask
                mask='aaaa 9999 9999'
                beforeMaskedStateChange={beforeMaskedStateChange}
                name='healthInsuranceNumber'
                value={values.healthInsuranceNumber}
                error={
                  errors.healthInsuranceNumber && touched.healthInsuranceNumber
                }
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={false}
              >
                <TextInputField
                  label="Numéro d'assurance maladie*"
                  helperText={
                    touched.healthInsuranceNumber
                      ? errors.healthInsuranceNumber
                      : ''
                  }
                />
              </InputMask>

              <InputMask
                mask='9999/99'
                beforeMaskedStateChange={beforeMaskedStateChange}
                name='expirationDate'
                value={values.expirationDate}
                error={errors.expirationDate && touched.expirationDate}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={false}
              >
                <TextInputField
                  label="Date d'expiration* AAAA/MM"
                  helperText={
                    touched.expirationDate ? errors.expirationDate : ''
                  }
                />
              </InputMask>
            </div>

            <div
              css={`
                ${theme.layouts.grid('400px', rem(32), 'auto-fill')}
                grid-column-gap: ${rem(32)};
                grid-row-gap: ${rem(32)};
                align-items: baseline;
              `}
            >
              <TextInputField
                label='Nom de votre médecin de famille'
                name='familyDoctorName'
                value={values.familyDoctorName}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <TextInputField
                label='Nom de votre dentiste'
                name='dentistName'
                value={values.dentistName}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <TextInputField
                label='Nom de votre pharmacie'
                name='pharmacyName'
                value={values.pharmacyName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <p>* Champs obligatoires</p>
        </FieldsetContent>
      </Fieldset>
    </Stack>
  );
};

export default MedicalInformationStep;

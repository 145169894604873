import styled, { css } from 'styled-components';
import { rem } from 'polished';

import theme from '../../styles/theme';
import { greaterThan } from '../../utils/mediaQuery';

export const Legend = styled.p`
  margin-top: ${rem(15)};

  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.medium};

  ${({ required }) =>
    required &&
    css`
      :after {
        content: '*';
      }
    `}
`;

export const RadioGroup = styled.div`
  display: flex;

  flex-wrap: wrap;

  align-items: center;
  margin: 0 ${rem(-16)};

  > * {
    margin: ${rem(8)} ${rem(16)};
  }
`;

export const ErrorWrapper = styled.div``;

export const ChildrenWrapper = styled.div``;

export const Group = styled.div`
  display: grid;
  grid-column: span 2;
  grid-column-gap: ${rem(32)};
  grid-template-columns: subgrid;

  @supports not (grid-template-columns: subgrid) {
    ${greaterThan(576)} {
      grid-template-columns: ${({ inline }) =>
        inline ? 'minmax(220px, 360px) minmax(264px, 1fr)' : '1fr'};
    }
  }

  ${ErrorWrapper} {
    grid-column: 1 / -1;
  }

  ${ChildrenWrapper} {
    grid-column: 1 / -1;
  }
`;
